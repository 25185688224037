import $ from "jquery";
import 'slick-carousel';
import 'node_modules/slick-carousel/slick/slick-theme.scss';
import 'node_modules/slick-carousel/slick/slick.scss';

class CPM_Carousel {
    constructor() {
        this.init();

        $(window).on('load', this.init.bind(this));
        $(window).on('resize', this.init.bind(this));
        $(window).on('orientationchange', this.init.bind(this));
        $('#mainMenuModal').on('shown.bs.modal', function () {
            $(window).trigger('resize');
        })
    }

    static add_carousel(el, width, opt = []) {
        if (el !== 'undefined') {

            opt['dots'] = true;
            opt['infinite'] = true;
            opt['speed'] = 800;
            opt['slidesToShow'] = 1;

            if (el.hasClass('adp-h')) {
                // console.log('slick H')
                opt['centerMode'] = true;
                opt['adaptiveHeight'] = true
            } else if (el.hasClass('adp-w')) {
                // console.log('slick W')
                opt['centerMode'] = true;
                opt['variableWidth'] = true
            }

            if (el.hasClass('adp-md-2')) {
                opt['slidesToShow'] = 2;
                opt['responsive'] = [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 1
                        }
                    }
                ]
            }

            if (el.hasClass('sj-slide-4s')) {
                opt['autoplaySpeed'] = 4000
            }
            if (el.hasClass('sj-slide-5s')) {
                opt['autoplaySpeed'] = 5000
            }
            if (el.hasClass('sj-slide-6s')) {
                opt['autoplaySpeed'] = 6000
            }
            if (el.hasClass('sj-slide-8s')) {
                opt['autoplaySpeed'] = 8000
            }
            if (el.hasClass('sj-slide-10s')) {
                opt['autoplaySpeed'] = 10000
            }
            if (el.hasClass('sj-slide-auto')) {
                opt['autoplay'] = true;
                opt['autoplaySpeed'] = 3000
            }

            if (el.hasClass('sj-slide-fade')) {
                opt['fade'] = true;
                opt['cssEase'] = 'ease-in-out'
            }

            if (el.hasClass('no-dots')) {
                opt['dots'] = false
            }

            if (el.hasClass('no-infinite')) {
                opt['infinite'] = false
            }

            if (el.hasClass('no-centerMode')) {
                opt['centerMode'] = false
            }

            if (el.hasClass('slide-xl-5')) {
                if (width > 992) {
                    opt['slidesToShow'] = 5;
                    opt['slidesToScroll'] = 2
                }
                opt['responsive'] = [
                    {
                        breakpoint: 1280,
                        settings: {
                            arrows: false,
                            slidesToShow: 4,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            arrows: false,
                            slidesToShow: 3,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 765,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
            if (el.hasClass('slide-xl-4')) {
                // console.log('slidexl4 active')
                if (width > 992) {
                    opt['slidesToShow'] = 4;
                    opt['slidesToScroll'] = 2
                }
                opt['responsive'] = [
                    {
                        breakpoint: 1280,
                        settings: {
                            arrows: false,
                            slidesToShow: 4,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 765,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
            if (el.hasClass('slide-xl-3')) {
                if (width > 992) {
                    opt['slidesToShow'] = 3;
                    opt['slidesToScroll'] = 1
                }
                opt['responsive'] = [
                    {
                        breakpoint: 1280,
                        settings: {
                            arrows: false,
                            slidesToShow: 3,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 765,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true
                        }
                    }
                ]
            }
            if (el.hasClass('slide-lg-4') && width < 992) {
                opt['slidesToShow'] = 4;
                opt['slidesToScroll'] = 4;
                opt['responsive'] = [
                    {
                        breakpoint: 992,
                        settings: {
                            arrows: false,
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
            if (el.hasClass('slide-lg-3') && width < 992) {
                opt['slidesToShow'] = 3;
                opt['slidesToScroll'] = 3
            }
            if (el.hasClass('slide-lg-2') && width < 992) {
                opt['slidesToShow'] = 2;
                opt['slidesToScroll'] = 2;
                opt['responsive'] = [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }

            if (el.hasClass('slide-md-4') && width < 768) {
                opt['slidesToShow'] = 5;
                opt['slidesToScroll'] = 5;
                opt['responsive'] = [
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    },
                    {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            }
            if (el.hasClass('slide-md-3') && width < 768) {
                opt['slidesToShow'] = 3;
                opt['slidesToScroll'] = 3;
                opt['responsive'] = [
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            slidesToShow: 1
                        }
                    }
                ]
            }

            if (el.hasClass('slide-md-2') && width < 768) {
                opt['slidesToShow'] = 2;
                opt['slidesToScroll'] = 2
            }
            if (el.hasClass('slide-sm-2') && width < 576) {
                opt['slidesToShow'] = 2;
                opt['slidesToScroll'] = 2

            }

            // console.log(width, opt)
            el.slick({...opt})
        }
    }

    static rm_carousel(el, width) {
        if (el !== 'undefined') {
            let slick = el.slick('getSlick');
            console.log('getSlick slide:', slick);
            if (slick) {
                console.log('unslick slide');
                el.slick('unslick')
            }
        }
    }

    static do_carousel(el, width) {
        let _el = {},
            diff = 0;

        if (!el.hasClass('slick-initialized')) {
            if (width < 576 - diff && el.hasClass('sj-slide-sm')) {
                _el = el;
                CPM_Carousel.add_carousel(_el, width)
                // console.log('slick-sm')
            } else if (width < 768 - diff && el.hasClass('sj-slide-md')) {
                _el = $(document).find('.sj-slide.sj-slide-md');
                CPM_Carousel.add_carousel(_el, width)
                // console.log('slick-md')
            } else if (width < 992 - diff && el.hasClass('sj-slide-lg')) {
                _el = $(document).find('.sj-slide.sj-slide-lg');
                CPM_Carousel.add_carousel(_el, width)
                // console.log('slick-lg')
            } else if (width > 992 - diff && el.hasClass('sj-slide-xl')) {
                _el = $(document).find('.sj-slide.sj-slide-xl');
                CPM_Carousel.add_carousel(_el, width)
                // console.log('slick-xl')
            } else {
                if (el.hasClass('sj-slide-full')) {
                    CPM_Carousel.add_carousel(el, width)
                }
            }
        } else {
            if (width > 576 - diff && el.hasClass('sj-slide-sm')) {
                _el = el;
                CPM_Carousel.rm_carousel(_el, width)
                // console.log('remove slick-sm')
            } else if (width > 768 - diff && el.hasClass('sj-slide-md')) {
                _el = $(document).find('.sj-slide.sj-slide-md');
                CPM_Carousel.rm_carousel(_el, width)
                // console.log('remove slick-md')
            } else if (width > 992 - diff && el.hasClass('sj-slide-lg')) {
                _el = $(document).find('.sj-slide.sj-slide-lg');
                CPM_Carousel.rm_carousel(_el, width)
                // console.log('remove slick-lg')
            } else if (width > 1280 - diff && el.hasClass('sj-slide-xl')) {
                _el = $(document).find('.sj-slide.sj-slide-xl');
                CPM_Carousel.rm_carousel(_el, width)
                // console.log('remove slick-xl')
            }
        }
    }

    init() {
        let h_carousel = $(document).find('.sj-slide'),
            h_c_nb = h_carousel.length,
            w_w = $(window).width();

        if (h_c_nb) {
            h_carousel.each(function (i, el) {
                CPM_Carousel.do_carousel($(el), w_w)
            })
        }
    }

    static reload() {
        let h_carousel = $(document).find('.sj-slide'),
            h_c_nb = h_carousel.length,
            w_w = $(window).width();

        if (h_c_nb) {
            h_carousel.each(function (i, el) {
                CPM_Carousel.do_carousel($(el), w_w)
            })
        }
    }
}

export default CPM_Carousel