import $ from "jquery";

class MenuNavigation {
    constructor() {
        this.open();
        this.close();
        this.subMenu();
    }

    subMenu() {
        const item_btn = $('#side-menu .menu-item');
        let item_arrow = $('#side-menu .menu-arrow');

        item_btn.on('click', function (e) {
            e.preventDefault();
            if ($(this).parent().parent().hasClass('open')) {
                window.location = $(this).attr('href');
            } else {
                $(this).parent().parent().toggleClass('open')
            }
        });

        item_arrow.on('click', function(e) {
            e.preventDefault();

            $(this).parent().parent().toggleClass('open')
        })
    }

    open() {
        let _this = this;
        const burger_btn = $('.burger-menu');

        burger_btn.on('click', function (e) {
            e.preventDefault();

            let side_menu = $('#side-menu');
            side_menu.removeAttr('style');

            _this.toggleMenu()
        })
    }

    close() {
        let _this = this;
        const close_btn = $('#side-menu .menu-close');

        close_btn.on('click', function (e) {
            e.preventDefault();
            _this.toggleMenu()
        })
    }

    toggleMenu() {
        const menu = document.querySelector('#side-menu');
        menu.classList.toggle('open');
    }
}

export default MenuNavigation