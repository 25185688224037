/**
 * Created by Rajo Lalaina
 */
'use strict';

//check the environment
import Navigation from "./components/MenuNavigation";

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!')
}

/****** IMPORT MODULES AND LIBRAIRIES *******/

/****** IMPORT STYLES *******/
import scss from '../styles/sass.scss'

/****** IMPORT JS *******/

import $ from 'jquery'

window.jQuery = $;
window.$ = $;

import CPM_Utilities from './tools/CPM_Utilities'

import CPM_MatchHeight from './modules/CPM_MatchHeight'
import CPM_Carousel from './modules/CPM_Carousel'
import CPM_Modal from './modules/CPM_Modal'

import MenuNavigation from './components/MenuNavigation'


/****** READY SCRIPTS *******/

$(document).ready(function () {
    /**
     * Modules
     */
    new CPM_MatchHeight();
    new CPM_Carousel();
    new CPM_Modal();

    /**
     * Tools
     */
    new CPM_Utilities();

    /**
     * Components
     */
    new MenuNavigation();

});