import $ from "jquery";

class CPM_Utilities {
    constructor() {

    }

    static scrollToTop() {
        let $body = $('html, body');

        let target = $('div').offset().top;
        $body.animate({'scrollTop': target}, 750)
    }

    static scrollTopClick($link) {
        let $body = $('html, body');
        if ($link.length) {

            $link.on('click', function (e) {
                e.preventDefault();
                let $this = $(this);
                let target = $($this.attr('href')).offset().top;
                $body.animate({'scrollTop': target}, 750)
            })
        }
    }

    static onlyUniqueArray(value, index, self) {
        return self.indexOf(value) === index
    }

    static add_weeks(dt, n) {
        return new Date(dt.setDate(dt.getDate() + (n * 7)));
    }
}

export default CPM_Utilities