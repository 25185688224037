import $ from "jquery";
import 'jquery-match-height'

class CPM_MatchHeight {
    constructor() {
        this.init();

        $(window).on('load', this.init.bind(this));
        $(window).on('resize', this.init.bind(this));
        $(window).on('resize', function () {
            $.fn.matchHeight._update();
        });
        $(window).on('orientationchange', this.init.bind(this));
        $(window).on('orientationchange', function () {
            $.fn.matchHeight._update();
        })
    }

    init() {
        $(function () {
                $('.m-ht').matchHeight();
                $('.m-ht-1').matchHeight();
                $('.m-ht-2').matchHeight();
                $('.m-ht-3').matchHeight()
            }
        )
    }
}

export default CPM_MatchHeight