import $ from "jquery";

class CPM_Modal {
    constructor() {
        this.init();
    }

    init() {
        let _this = this;
        let openmodal = document.querySelectorAll('.modal-open');
        for (let i = 0; i < openmodal.length; i++) {
            openmodal[i].addEventListener('click', function (event) {
                event.preventDefault();
                _this.toggleModal()
            })
        }

        const overlay = document.querySelector('.modal-overlay');
        overlay.addEventListener('click', _this.toggleModal);

        let closemodal = document.querySelectorAll('.modal-close');
        for (let i = 0; i < closemodal.length; i++) {
            closemodal[i].addEventListener('click', _this.toggleModal)
        }

        document.onkeydown = function (evt) {
            evt = evt || window.event;
            let isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc")
            } else {
                isEscape = (evt.keyCode === 27)
            }
            if (isEscape && document.body.classList.contains('modal-active')) {
                _this.toggleModal()
            }
        };
    }

    toggleModal() {
        const body = document.querySelector('body');
        const modal = document.querySelector('.modal');
        modal.classList.toggle('opacity-0');
        modal.classList.toggle('pointer-events-none');
        body.classList.toggle('modal-active')
    }
}

export default CPM_Modal